import React, { Fragment, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import ECommerceIcon from '../../assets/images/homepage/e-commerce-icon.svg'
import MarketingIcon from '../../assets/images/homepage/marketing-icon.svg'
import IndustrialsIcon from '../../assets/images/homepage/industrials-icon.svg'
import RealEstateIcon from '../../assets/images/homepage/real-estate-icon.svg'

const query = graphql`
  {
    eCommerce: file(relativePath: { eq: "images/homepage/e-commerce.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    marketing: file(relativePath: { eq: "images/homepage/marketing.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    industrials: file(relativePath: { eq: "images/homepage/industrials.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    realEstate: file(relativePath: { eq: "images/homepage/real-estate.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bgDivider: file(relativePath: { eq: "images/global/bg-divider.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const TABS = {
  E_COMMERCE: 'e-commerce',
  MARKETING: 'marketing',
  INDUSTRIALS: 'industrials',
  REAL_ESTATE: 'real-estate',
}



const Markets = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(TABS.E_COMMERCE);
  const data = useStaticQuery(query);

  const eCommerce = data.eCommerce.childImageSharp.fluid;
  const marketing = data.marketing.childImageSharp.fluid;
  const industrials = data.industrials.childImageSharp.fluid;
  const realEstate = data.realEstate.childImageSharp.fluid;
  const bgDivider = data.bgDivider.childImageSharp.fluid;

  const content = [
    {
      tabIcon: <ECommerceIcon />,
      image: eCommerce,
      headline: `${t('markets.eCommerce')}`,
      text: `${t('home.markets.eCommerce')}`,
      linkTo: '/e-commerce',
      key: TABS.E_COMMERCE
    },
    {
      tabIcon: <MarketingIcon />,
      image: marketing,
      headline: `${t('markets.marketing')}`,
      text: `${t('home.markets.marketing')}`,
      linkTo: '/marketing',
      key: TABS.MARKETING
    },
    {
      tabIcon: <IndustrialsIcon />,
      image: industrials,
      headline: `${t('markets.industrials')}`,
      text: `${t('home.markets.industrials')}`,
      linkTo: '/industrials',
      key: TABS.INDUSTRIALS
    },
    {
      tabIcon: <RealEstateIcon />,
      image: realEstate,
      headline: `${t('markets.realEstate')}`,
      text: `${t('home.markets.realEstate')}`,
      linkTo: '/real-estate',
      key: TABS.REAL_ESTATE
    },
  ];

  return (
    <section className="m-markets section-padding">
      <div className="_wr">
        <div className="_w">
          <div className="_12 m-markets__contentTop">
            <h2 className="m-markets__contentTop--title">{t('home.markets.headline')}</h2>
            <span className="a-line -red"></span>
          </div>
          <div className="ofs_l2 _l8">
            <p className="m-markets__text">{t('home.markets.text')}</p>
          </div>
        </div>
        <div className="m-markets__tabs">
          <div className="_w">
            <div className="_l5">
              <div className="m-markets__tabs--buttons">
                {content.map(({ key, tabIcon, headline }, i) => (
                  <button className={`m-markets__tabs--button ${key === activeTab ? '-active' : ''}`} key={i} onClick={() => setActiveTab(key)} onMouseEnter={() => setActiveTab(key)}>
                    {tabIcon}
                    <h3>{headline}</h3>
                  </button>
                ))}
              </div>
            </div>
            {content.map(({ key, image, headline, text, linkTo }) => (
              <Fragment key={key}>
                {key === activeTab && <div className="_l7">
                  <div className="m-markets__tabs--panel">
                    <div className="m-markets__tabs--panelText">
                      <h3>{headline}</h3>
                      <p>{text}</p>
                      <Link className="a-btn -redBorder a-hover -effectThree" to={linkTo} >
                        {t('buttons.readMore')}
                        <span className="a-arrow -long"></span>
                      </Link>
                    </div>
                    <div className="m-markets__tabs--panelImage">
                      <Image fluid={image} alt="eCommerce" />
                    </div>
                  </div>
                </div>}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Markets;
