import * as React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next'
import HeaderPrimary from "../components/headers/header-primary";
import NumberedCards from "../components/homepage/numbered-cards";
import Layout from "../components/layout/layout";
import Seo from "../components/seo/seo";
import Text from "../components/global/text";
import Projects from "../components/global/projects";
import Icons from "../components/homepage/icons";
import Develop from "../components/global/develop";
import Language from "../components/homepage/language";
import Markets from "../components/homepage/markets";
import BgImage from "../assets/images/headers/home.png"
import { graphql } from 'gatsby'
import BgDivider from "../components/global/bg-divider";



const IndexPage = () => {
  const { t } = useTranslation()
  const headerContent = {
    title: `${t('header.home.headline')}`,
    text: `${t('header.home.text')}`,
    buttonText: `${t('buttons.seeOurWork')}`,
    buttonUrl: '/our-work',
    imageUrl: BgImage,
    hasCubes: true
  };

  const numberedCardsContent = {
    title: `${t('home.numberedCards.headline')}`,
    text: `${t('home.numberedCards.text')}`,
    cards: [
      {
        title: `${t('home.numberedCards.cards.appDev.headline')}`,
        text: `${t('home.numberedCards.cards.appDev.text')}`,
        buttonText: `${t('home.numberedCards.cards.appDev.button')}`,
        buttonUrl: "/application-development",
      },
      {
        title: `${t('home.numberedCards.cards.engTeam.headline')}`,
        text: `${t('home.numberedCards.cards.engTeam.text')}`,
        buttonText: `${t('home.numberedCards.cards.engTeam.button')}`,
        buttonUrl: "/engineering-team",
      },
      {
        title: `${t('home.numberedCards.cards.reEng.headline')}`,
        text: `${t('home.numberedCards.cards.reEng.text')}`,
        buttonText: `${t('home.numberedCards.cards.reEng.button')}`,
        buttonUrl: "/application-re-engineering",
      },
      {
        title: `${t('home.numberedCards.cards.otherServices.headline')}`,
        text: `${t('home.numberedCards.cards.otherServices.text')}`,
        buttonText: `${t('home.numberedCards.cards.otherServices.button')}`,
        buttonUrl: "/other-services",
      },
    ]
  }

  // Text Component
  const textContent = {
    title: `${t('home.rightPartner.headline')}`,
    text: `${t('home.rightPartner.text')}`,
  };

  const textContent2 = {
    title: `${t('home.icons.headline')}`,
    text: `${t('home.icons.text')}`,
  };
  return (
    <Layout>
      <Seo title="Homepage" />
      <HeaderPrimary headerContent={headerContent} />
      <BgDivider />
      <Markets />
      <NumberedCards numberedCardsContent={numberedCardsContent} />
      <Text textContent={textContent} />
      <Projects />
      <Text textContent={textContent2} cssModifer="-white -icons" />
      <Icons />
      <Language />
      <Develop />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`