import React, { Fragment, useEffect, useState } from "react";
import GroupIcon from "../../assets/images/homepage/group-people.svg";
import SetupIcon from "../../assets/images/homepage/setup.svg";
import FocusIcon from "../../assets/images/homepage/focus.svg";
import ReinventedIcon from "../../assets/images/homepage/reinvented.svg";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SquaresWideBcg from "../../assets/images/homepage/squares-wide.png";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useTranslation } from "react-i18next";

const breakpoints = {
  576: {
    slidesPerView: 1.6,
    spaceBetween: 30
  },
  768: {
    slidesPerView: 2.5,
  },
  1200: {
    slidesPerView: 4,
    spaceBetween: 40
  }
}

const Icons = () => {
  const { t } = useTranslation()
  const breakpoints = useBreakpoint();

  const data = [
    {
      icon: <FocusIcon />,
      title: `${t('home.icons.cards.first.headline')}`,
      text: `${t('home.icons.cards.first.text')}`,
    },
    {
      icon: <ReinventedIcon />,
      title: `${t('home.icons.cards.second.headline')}`,
      text: `${t('home.icons.cards.second.text')}`,
    },
    {
      icon: <GroupIcon />,
      title: `${t('home.icons.cards.third.headline')}`,
      text: `${t('home.icons.cards.third.text')}`,
    },
    {
      icon: <SetupIcon />,
      title: `${t('home.icons.cards.fourth.headline')}`,
      text: `${t('home.icons.cards.fourth.text')}`,
    },
  ];

  return (
    <section className="m-icons">
      <div className="_wr">
        {breakpoints.sm ? <Swiper
          spaceBetween={20}
          slidesPerView={1.4}
          breakpoints={breakpoints}
        >
          {data.map(({ title, icon, text }, i) => (
            <SwiperSlide key={i}>
              <div className=" m-icons__card" >
                <div className="m-icons__card--iconTitle" style={{ backgroundImage: `url(${SquaresWideBcg})` }}>
                  <div className="m-icons__card--icon">
                    {icon}
                  </div>
                  <h3 className="m-icons__card--title">{title}</h3>
                </div>
                <p className="m-icons__card--text">{text}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
          :
          <div className="_w">
            {data.map(({ title, icon, text }, i) => (
              <div className="_6 m-icons__cardCol" key={i} >
                <div className="m-icons__card">
                  <div className="m-icons__card--iconTitle" style={{ backgroundImage: `url(${SquaresWideBcg})` }}>
                    <div className="m-icons__card--icon">
                      {icon}
                    </div>
                    <h3 className="m-icons__card--title">{title}</h3>
                  </div>
                  <p className="m-icons__card--text">{text}</p>
                </div>
              </div>
            ))}
          </div>}
      </div>

    </section>
  );
};

export default Icons;
