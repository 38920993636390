import React from "react";
import { Link } from 'gatsby-plugin-react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SquaresBcg from "../../assets/images/homepage/squares.png";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';



const breakpointsObj = {
  576: {
    slidesPerView: 1.6,
  },
  768: {
    slidesPerView: 2.5,
  },
  1286: {
    slidesPerView: 3.5,
    spaceBetween: 30
  },
  1400: {
    slidesPerView: 4,
    spaceBetween: 30
  }
}



function NumberedCards({ numberedCardsContent }) {
  const breakpoints = useBreakpoint();


  const cardContent = (title, text, buttonText, buttonUrl, key) => {
    return (

      <div className="m-numberedCards__card " >
        <h3 className="m-numberedCards__card--title">{title}</h3>
        <span className="m-numberedCards__card--number"><span className="m-numberedCards__card--numberNum">0{key + 1}</span> <span className="m-numberedCards__card--numberBcg" style={{ backgroundImage: `url(${SquaresBcg})` }}></span></span>
        <p className="m-numberedCards__card--text">{text}</p>
        {buttonUrl &&
          <Link className="a-btn -redBorder a-hover -effectThree" to={buttonUrl}>
            {buttonText}
            <span className="a-arrow -long"></span>
          </Link>
        }
      </div>
    )
  }
  return (

    <section className="m-numberedCards">
      <div className="_wr">

        <div className="_w m-numberedCards__contentTop">
          <div className="_l8">
            <h2 className="m-numberedCards__contentTop--title">
              {numberedCardsContent.title}
            </h2>
            <span className="a-line -red"></span>
            {numberedCardsContent.text &&
              <p className="m-numberedCards__contentTop--text">
                {numberedCardsContent.text}
              </p>
            }
          </div>
        </div>
        <div className="m-numberedCards__contentBottom">
          {breakpoints.md ? <Swiper
            spaceBetween={20}
            slidesPerView={1.4}
            breakpoints={breakpointsObj}
          >
            {numberedCardsContent.cards.map(({ title, text, buttonText, buttonUrl }, key) => (
              <SwiperSlide key={key}>
                {cardContent(title, text, buttonText, buttonUrl, key)}
              </SwiperSlide>
            ))}
          </Swiper>
            :
            <div className="_w m-numberedCards__cardsContainer">
              {numberedCardsContent.cards.map(({ title, text, buttonText, buttonUrl }, key) => (
                <div className="_3" key={key}>
                  {cardContent(title, text, buttonText, buttonUrl, key)}
                </div>
              ))}
            </div>}
        </div>
      </div>
    </section >
  );
}

export default NumberedCards;
