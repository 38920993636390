import React from "react";
import { Link } from 'gatsby-plugin-react-i18next'
import Cubes from '../../assets/images/headers/cubes.svg'
import CubesMobile from '../../assets/images/headers/cubes-mobile.svg'

const HeaderHome = ({ headerContent }) => {

  return (
    <header className="m-headerPrimary">
      <div className="_wr m-headerPrimary__wrapper" style={{ backgroundImage: `url("${headerContent.imageUrl}")` }}>
        {headerContent.hasCubes &&
          <div className="m-cubesBg">
            <CubesMobile />
            <Cubes />
          </div>
        }
        <div className="_w m-headerPrimary__content">
          <div className="_xs10 _m6 m-headerPrimary__textContent">
            <h1 className="m-headerPrimary__textContent--title">
              {headerContent.title}
            </h1>
            <p className="m-headerPrimary__textContent--text">
              {headerContent.text}
            </p>
            <Link to={headerContent.buttonUrl} className="a-btn -red">
              {headerContent.buttonText}
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderHome;
