import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";
import { useTranslation } from "react-i18next";

const query = graphql`
  {
    java: file(relativePath: { eq: "images/about-us/java.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    node: file(relativePath: { eq: "images/about-us/node.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    spring: file(relativePath: { eq: "images/about-us/spring.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    symfony: file(relativePath: { eq: "images/about-us/symfony.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    react: file(relativePath: { eq: "images/about-us/react.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    php: file(relativePath: { eq: "images/about-us/php.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vue: file(relativePath: { eq: "images/about-us/vue.png" }) {
      name
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    flutter: file(relativePath: { eq: "images/about-us/flutter.png" }) {
      name
      childImageSharp {
        fluid(quality: 100, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Language = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(query);


  const languageContent = [{
    logo: data.java.childImageSharp.fluid,
  },
  {
    logo: data.spring.childImageSharp.fluid,
  },
  {
    logo: data.php.childImageSharp.fluid,
  },
  {
    logo: data.symfony.childImageSharp.fluid,
  },
  {
    logo: data.react.childImageSharp.fluid,
  },
  {
    logo: data.vue.childImageSharp.fluid,
  },
  {
    logo: data.node.childImageSharp.fluid,
  },
  {
    logo: data.flutter.childImageSharp.fluid,
  },
  ];

  return (
    <section className="m-language section-padding">
      <div className="_wr">
        <h2 className="m-language__title -thin">{t('home.language')}</h2>
        <div className="_w m-language__logoContainer">
          {languageContent.map(({ logo }, i) => {
            return (
              <div key={i} className="_5 _m4 _l3 m-language__logo">
                <div className="m-language__logo--content">
                  <Image fluid={logo} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Language;
